
import { mdiChevronUp, mdiClose, mdiChevronRight } from "@mdi/js"
import anime from "animejs"

export default {
    /** Currently used for business overlay.
     * Requires relative positioned parent element
     *  */
    name: "Overlay",
    props: {
        /* If overlay should be visible */
        value: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            mdiClose,
            mdiChevronUp,
            mdiChevronRight,
            isOverlayOpen: false,
            showHoverBackground: true,
            isOpen: false,
            animeDuration: 400,
            savedTop: null,
            overlayId: null,
        }
    },
    computed: {
        isBackdropFilterSupported() {
            if (CSS)
                return (
                    CSS.supports("-webkit-backdrop-filter", "blur(10px)") ||
                    CSS.supports("backdrop-filter", "blur(10px)")
                )
            else return false
        },
        animeBackground() {
            return this.isBackdropFilterSupported
                ? ["rgba(255, 255, 255, 0)", "rgba(255, 255, 255, 0.4)"] // animates from transparent to white with opacity 0.4
                : ["rgba(255, 255, 255, 0)", "rgba(255, 255, 255, 1)"] // animates from transparent to white (when the backdrop-filter is not supported)
        },
        overlayClasses() {
            return {
                hover: this.showHoverBackground,
                "flex-column": this.isOverlayOpen,
                "cursor-pointer px-2 py-1": !this.isOverlayOpen,
                "overlay--open": this.isOverlayOpen,
            }
        },
    },
    watch: {
        value(newVal) {
            if (newVal) {
                if (!this.savedTop) {
                    this.savedTop = getComputedStyle(this.$refs.overlay).top
                }
                this.onOpen()
            } else {
                this.onClose()
            }
        },
    },
    created() {
        this.overlayId = `overlay-${this.$getUID()}`
    },
    methods: {
        getAnimeOptions() {
            return {
                targets: `#${this.overlayId}`,
                duration: this.animeDuration,
                background: this.animeBackground,
                width: ["calc(100% - 32px)", "calc(100% - 0px)"], // animates from (100%-32px) to (100%)
                height: ["calc(0% + 56px)", "calc(100% + 0px)"], // animates from (56px) to (100%)
                top: [this.savedTop, "0px"], // animates from original "top" to (0)
                left: ["16px", "0px"],
                update: function ({ animatables, progress }) {
                    // animates the backdrop-filter (not supported by anime directly)
                    const value = `blur(${(progress / 100) * 30}px)`
                    const style = animatables[0].target.style
                    style.backdropFilter = value
                    style["-webkit-backdrop-filter"] = value
                },
            }
        },
        onOpen() {
            if (this.isOverlayOpen) return
            this.isOverlayOpen = true
            this.showHoverBackground = false
            anime({
                ...this.getAnimeOptions(),
                easing: "cubicBezier(0.33, 1, 0.68, 1)", // easeOutCubic
            })
            setTimeout(() => {
                this.isOpen = true
            }, 100)
        },
        onClose() {
            this.isOverlayOpen = false
            this.isOpen = false
            anime({
                ...this.getAnimeOptions(),
                direction: "reverse",
                easing: "cubicBezier(0.32, 0, 0.67, 0)", // easeInCubic (with reverse direction becomes an easeOutCubic)
            })
            setTimeout(() => {
                this.showHoverBackground = true
            }, this.animeDuration)
        },
    },
}
